import React from 'react';
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet';
/* eslint-disable no-unused-vars */
import { Global, css, ThemeProvider } from '@emotion/react'
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import Theme from './Theme';
import kjn from '../images/kjn.png';

const mq = Theme.breakpoints.map(
    bp => `@media (max-width: ${bp})`
);

const globalStyle = theme => ({
    body: {
        color: theme.colours.text,
        backgroundColor: theme.colours.background,
        fontSize: '16px',
        margin: 0,
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.body,
        boxSizing: 'border-box',
        overflowY: 'scroll',
    },
    header: {
        backgroundColor: theme.colours.primary,
        '>nav':{
            fontSize: theme.fontSizes[3],
            backgroundImage: `url(${kjn})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            maxWidth: 720,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            '.social-links': {
                marginLeft: '1rem',
            },
            [mq[1]]: {
                paddingLeft: theme.space(2),
                paddingRight: theme.space(2),
                backgroundPosition: `calc(100% - ${theme.space(2)}px)`,
            },
            [mq[2]]: {
                paddingTop: '2.5rem',
                backgroundSize: '200px',
                backgroundPosition: `center ${theme.space(0.5)}px`,
                flexDirection: 'column',
                alignItems: 'center',
                '.social-links': {
                    display: 'none',
                }
            },
        }
    },
    a: {
        textDecoration: 'none',
        fontWeight: theme.fontWeights.link,
    },
    '#gatsby-focus-wrapper': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        maxWidth: 720,
        margin: '0 auto',
        ul: {
            paddingLeft: '1rem',
            li: {
                paddingTop: theme.space(1),
                paddingBottom: theme.space(1),
                'svg, span': {
                    verticalAlign: 'middle',
                },
            }
        },
        h1: {
            fontFamily: theme.fonts.heading,
            fontWeight: theme.fontWeights.heading,
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: '32px',
            color: theme.colours.primary,
            marginTop: theme.space(1.5),
            marginBottom: theme.space(0.25),
            '+p': {
                textAlign: 'center'
            }
        },
        h2: {
            color: theme.colours.secondary,
            display: 'inline-block',
            position: 'relative',
            fontFamily: theme.fonts.heading,
            fontWeight: theme.fontWeights.heading,
            fontSize: '24px',
            marginTop: theme.space(3),
            marginBottom: theme.space(1),
            textTransform: 'uppercase',
            '&::after': {
                content: "''",
                display: 'block',
                position: 'absolute',
                height: 2,
                width: '100%',
                background: theme.colours.emphasis,
            }
        },
        p: {
            marginTop: theme.space(1),
            marginBottom: theme.space(1),
            lineHeight: 1.5,
        },
        a: {
            color: theme.colours.emphasis,
            ':hover': {
                color: theme.colours.primary,
            },
        },
        '&.home': {
            img: {
                maxHeight: '20rem',
                maxWidth: '100%',
            }
        },
        '&.contact': {
            ul: {
                listStyle:'none',
                paddingLeft: '0.25rem',
            },
            a: {
                fontSize: '20px',
                span: {
                    marginLeft: '.75rem'
                }
            },
        }
    },
});

const HeaderLink = (props) => {
    const { theme, noUnderline, ...remaining } = props;
    const noUnderlineStyle = !noUnderline ? {
        '::after': {
            content: '""',
            display: 'block',
            borderTop: '2px solid transparent',
            position: 'absolute',
            left: '8px',
            right: `calc(100% - 8px)`,
            transition: 'all 0.2s ease',
        },
        ':hover::after': {
            borderTop: '2px solid currentColor',
            right: '8px',
        }
    } : {};
    const style = {
        paddingLeft: theme.space(1),
        paddingRight: theme.space(1),
        paddingTop: theme.space(1.5),
        paddingBottom: theme.space(1.5),
        display: 'inline-block',
        position: 'relative',
        transition: 'all 0.2s ease',
        color: theme.colours.muted,
        ':hover': {
            color: theme.colours.background,
        },
        ...noUnderlineStyle,
    };
    return props.to.startsWith('http') ? (<a css={style} href={remaining.to} target={remaining.target}>{remaining.children}</a>) : (<Link css={style} {...remaining} />);
}

const Layout = ({ pageContext, children }) => {
    const pageTitle = pageContext ? pageContext.frontmatter.title : '';
    const normalisedTitle = pageTitle.replace(' ','-').toLowerCase();
    return <ThemeProvider theme={Theme}>
        <Global styles={globalStyle} />
        <Helmet title={`Kathryn Jones Nutrition${pageTitle ? `: ${pageTitle}` : ''}`} />
        <header>
            <nav>
                <HeaderLink theme={Theme} to="/">Home</HeaderLink>
                <HeaderLink theme={Theme} to="/services">Services</HeaderLink>
                <HeaderLink theme={Theme} to="/contact">Contact</HeaderLink>
            <div className="social-links">
                <HeaderLink theme={Theme} noUnderline target="_blank" to="https://www.facebook.com/kathrynjonesnutrition">
                    <FaFacebook size={24} />
                </HeaderLink>
                <HeaderLink theme={Theme} noUnderline target="_blank" to="https://www.instagram.com/_kjnutrition_">
                    <FaInstagram size={24} />
                </HeaderLink>
            </div>
            </nav>
        </header>
        <main className={normalisedTitle} css={theme => ({ paddingTop: theme.space(1), paddingBottom: theme.space(4), paddingLeft: theme.space(4), paddingRight: theme.space(4), flex: 1 })}>{children}</main>
        <footer css={theme => ({ backgroundColor: theme.colours.text, color: theme.colours.background, paddingTop: theme.space(1), paddingBottom: theme.space(1), paddingLeft: theme.space(4), paddingRight: theme.space(4) })}>
            &copy; 2021
            &nbsp;Kathryn Jones Nutrition Pty Ltd
        </footer>
    </ThemeProvider>
}

export default Layout;