const theme = {
    breakpoints: ['992px', '768px', '600px'],
    space: multiplier => 8 * multiplier,
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
    fonts: {
        body: `'Red Hat Text', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif`,
        heading: `Spectral, 'Times New Roman', Times, serif`,
    },
    fontWeights: {
        body: 400,
        heading: 500,
        link: 500,
    },
    colours: {
        text: '#454a48',
        muted: '#97c3aa',
        primary: '#294634',
        secondary: '#587d67',
        emphasis: '#88603b',
        background: '#f0eee9',
    },
}
export default theme;